
import { Options, mixins } from "vue-class-component";
import { AlbumAndAssetManagementMixin, RoutingUtilityMixin } from "@/mixins";
import {
  AssetsInterface,
  FolderDataInterface,
  FoldersWithAssetsInterface,
  NewestAlbumType,
} from "@/types";
import { assetsModule } from "@/store";
import AlbumComponent from "./AlbumComponent/index.vue";
import AssetComponent from "./AssetComponent/index.vue";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";
import { useMq } from "vue3-mq";

@Options({
  name: "HomeSliderAlbums",
  components: {
    AlbumComponent,
    AssetComponent,
    SvgIcon,
  },
})
export default class HomeSliderAlbums extends mixins(
  AlbumAndAssetManagementMixin,
  RoutingUtilityMixin
) {
  private mq = useMq();

  protected get dataLoadingInProgress(): boolean {
    return assetsModule.getAllDataLoader;
  }

  private get getAlbumsData(): any {
    return assetsModule.hasFilterActive
      ? assetsModule.getFilteredAlbums
      : assetsModule.getFoldersWithAssets;
  }

  protected get filteredNewestAssets(): AssetsInterface[] {
    const filters = assetsModule.getFilters;
    const assets = assetsModule.getNewestAssets;
    if (!assets) return [];

    return assets.filter((asset: AssetsInterface) => {
      const shouldFilterByAssetType =
        filters.assetTypes && filters.assetTypes.length > 0;

      return (
        !shouldFilterByAssetType || filters.assetTypes.includes(asset.assetType)
      );
    });
  }

  protected get newestAlbum(): NewestAlbumType {
    return {
      name: "Newest",
      slug: "newest",
      assets: this.filteredNewestAssets,
      folders: [],
    };
  }

  protected get rootAlbums(): FoldersWithAssetsInterface[] {
    return this.getAlbumsData.filter(
      (album: FoldersWithAssetsInterface) => !album.parentId
    );
  }

  private get isNewestAlbumPath(): boolean {
    return this.$route.params.albumPath?.includes("newest") ?? false;
  }

  protected get currentAlbum():
    | FoldersWithAssetsInterface
    | NewestAlbumType
    | null {
    if (this.isNewestAlbumPath) {
      return this.newestAlbum;
    }

    return (
      this.findNestedAlbumById(
        this.currentAlbumId as string,
        this.getAlbumsData
      ) || null
    );
  }

  protected get childAlbums(): FolderDataInterface[] {
    return this.currentAlbum
      ? this.currentAlbum.folders.filter(
          (album: FoldersWithAssetsInterface) =>
            album.parentId === this.currentAlbumId
        )
      : [];
  }

  protected get currentAlbumHasChildren(): boolean {
    return this.currentAlbum?.folders.length > 0;
  }

  protected get currentAlbumId(): string | null {
    return this.getCurrentAlbumId(this.getAlbumPathArray, this.getAlbumsData);
  }

  protected goBack(): void {
    const currentRouteAsArray: string[] = [...this.$route.params.albumPath];

    currentRouteAsArray.pop();
    this.redirectToAlbum(currentRouteAsArray);
  }
}
