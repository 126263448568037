import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-220e414c")
const _hoisted_1 = { class: "image-holder" }
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = { class: "title" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageWithLoader = _resolveComponent("ImageWithLoader")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.newest ? '/albums/newest' : _ctx.getFullAlbumsRouteByAlbumId(_ctx.album.id, 'string'),
    class: _normalizeClass(['album', _ctx.hasChildren(_ctx.album.id) && 'has-sub-album'])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.newest)
            ? (_openBlock(), _createBlock(_component_ImageWithLoader, {
                ref: "image",
                key: _ctx.album?.src,
                src: _ctx.album?.src,
                d3model: false,
                alt: _ctx.album?.src
              }, null, 8, ["src", "alt"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.album.name), 1)
    ]),
    _: 1
  }, 8, ["to", "class"]))
}